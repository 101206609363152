<template>
  <div class="registerlogin  ">
    <div class="container pt-md-4 pt-2 pb-md-5 pb-4">
      <div class="row justify-content-center">
        <div class="col-10 ">
          <div class="row justify-content-evenly">
            <div class="col-12  col-lg-5 align-items-md-start bg-white p-0">
              <ul class="nav nav-tabs w-100" id="myTab" role="tablist">
                <li class="nav-item flex-fill" role="presentation">
                  <button
                    class="nav-link  w-100"
                    id="register-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#register"
                    type="button"
                    role="tab"
                    :class="{ active: $store.state.loginState === 'register' }"
                    @click="changeVuexLoginState('register')"
                  >
                    註冊會員
                  </button>
                </li>
                <li class="nav-item flex-fill" role="presentation">
                  <button
                    class="nav-link  w-100"
                    id="oldMember-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#oldMember"
                    type="button"
                    role="tab"
                    :class="{ active: $store.state.loginState === 'login' }"
                    @click="changeVuexLoginState('login')"
                  >
                    會員登入
                  </button>
                </li>
                <!-- <li class="nav-item flex-fill" role="presentation">
                  <button
                    class="nav-link w-100"
                    id="member-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#member"
                    type="button"
                    role="tab"
                    :class="{ active: $store.state.loginState === 'new' }"
                    @click="changeVuexLoginState('new')"
                  >
                    會員登入
                  </button>
                </li> -->
              </ul>
              <div class="tab-content" id="myTabContent">
                <!-- ===分頁1註冊會員=== -->
                <div
                  class="tab-pane  pt-5 pb-3 px-4"
                  id="register"
                  role="tabpanel"
                  :class="{ active: $store.state.loginState === 'register' }"
                >
                  <Form
                    class="form_registration"
                    v-slot="{ errors }"
                    @submit="registerSubmit"
                  >
                    <!-- //* --驗證姓名-- -->
                    <label class="" for="register1">*姓名</label>
                    <div class=" mb-4">
                      <Field
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors['姓名'] }"
                        id="register1"
                        placeholder="請輸入姓名"
                        name="姓名"
                        :rules="isNameToLong"
                        autocomplete="off"
                        v-model="form.FullName"
                      />
                      <ErrorMessage
                        name="姓名"
                        class="invalid-feedback"
                      ></ErrorMessage>
                    </div>
                    <!-- //* --驗證信箱-- -->
                    <label class="" for="register2">*電子郵件</label>
                    <div class=" mb-4">
                      <Field
                        type="email"
                        class="form-control"
                        :class="{ 'is-invalid': errors['信箱'] }"
                        id="register2"
                        placeholder="請輸入電子郵件"
                        name="信箱"
                        :rules="checkEmail"
                        autocomplete="off"
                        v-model="form.Email"
                      ></Field>
                      <ErrorMessage
                        name="信箱"
                        class="invalid-feedback"
                      ></ErrorMessage>
                    </div>
                    <!-- //* 提供生日不驗證 -->
                    <label class=" " for="register3">*生日</label>
                    <Datepicker
                      v-model="form.UserBirthday"
                      class="mb-4 "
                      placeholder="西元年/月/日"
                      light
                      autoApply
                      :enableTimePicker="false"
                      locale="zh"
                      :format="format"
                      noToday
                      hideOffsetDates
                      :startDate="startDate"
                      :yearRange="[1940, 2010]"
                      :escClose="true"
                      hideInputIcon
                      calendarCellClassName="dp-custom-cell"
                      name="birth"
                      required
                      id="register3"
                    />
                    <!-- //* 驗證手機 -->
                    <label class=" " for="register4">*手機號碼</label>
                    <div class=" mb-4">
                      <Field
                        type="tel"
                        class="form-control"
                        :class="{ 'is-invalid': errors['手機'] }"
                        id="register4"
                        placeholder="請輸入手機號碼"
                        name="手機"
                        :rules="isPhone"
                        autoCompleteType="disabled"
                        autocomplete="off"
                        v-model="form.PhoneNum"
                      ></Field>
                      <ErrorMessage
                        name="手機"
                        class="invalid-feedback"
                      ></ErrorMessage>
                    </div>
                    <!-- //* 手機驗證碼 -->
                    <label class=" " for="register5">*手機驗證碼</label>
                    <div
                      class="mb-4 w-100 d-flex flex-column flex-lg-column flex-xl-row mb-4"
                    >
                      <div class="d-flex flex-column w-100 ">
                        <Field
                          type="number"
                          class="form-control mb-1 mb-md-0 "
                          :class="{ 'is-invalid': errors['手機驗證碼'] }"
                          id="register5"
                          placeholder="請輸入手機驗證碼(每日限定3次)"
                          name="手機驗證碼"
                          rules="required"
                          autoCompleteType="disabled"
                          autocomplete="off"
                          v-model="form.OtpCode"
                        ></Field>
                        <ErrorMessage
                          name="手機驗證碼"
                          class="invalid-feedback "
                        ></ErrorMessage>
                      </div>
                      <div
                        class="d-flex flex-md-column ms-0 ms-md-0 ms-xl-2 w-50 w-md-30 w-lg-50 w-xl-40 py-md-2 py-lg-1 p-xl-1"
                      >
                        <button
                          class="btn bg-primary text-white text-nowrap"
                          type="button"
                          id="button-addon2"
                          @click.prevent="getMobileMsgCode"
                          :disabled="!show"
                        >
                          <span v-show="show">獲取驗證碼</span>
                          <span v-show="!show" class="count  fs-7"
                            >{{ count }} 秒後 重新發送</span
                          >
                        </button>
                      </div>
                    </div>
                    <!-- //* 密碼建立 -->
                    <label class=" " for="register6">*密碼</label>
                    <div class="position-relative mb-4">
                      <Field
                        type="password"
                        class="form-control"
                        id="register6"
                        placeholder="請輸入密碼"
                        name="密碼"
                        :rules="checkPWD"
                        :class="{ 'is-invalid': errors['密碼'] }"
                        autocomplete="off"
                        v-model="form.SecretCode"
                      ></Field>
                      <ErrorMessage
                        name="密碼"
                        class="invalid-feedback"
                      ></ErrorMessage>
                      <a
                        href="#"
                        class="position-absolute input-icon"
                        @click.prevent="
                          passWordShow = !passWordShow;
                          showPW($event)
                        "
                      >
                        <div></div>
                      </a>
                    </div>
                    <!-- //* 確認密碼 -->
                    <label class=" " for="register7">*確認密碼</label>
                    <div class="position-relative mb-4">
                      <Field
                        type="password"
                        class="form-control"
                        id="register7"
                        placeholder="再次確認密碼"
                        name="確認密碼"
                        :rules="checkPWDagain"
                        :class="{ 'is-invalid': errors['確認密碼'] }"
                        autocomplete="off"
                        v-model="confirmPassword"
                      ></Field>
                      <ErrorMessage
                        name="確認密碼"
                        class="invalid-feedback"
                      ></ErrorMessage>
                      <a
                        href="#"
                        class="position-absolute input-icon d-block"
                        @click.prevent="
                          checkPassWord = !checkPassWord;
                          showCkPW($event)
                        "
                      >
                        <div></div>
                      </a>
                    </div>
                    <!-- //* --亂數驗證碼-- -->
                    <label class=" valid" for="register8">*驗證碼</label>
                    <div class="d-flex flex-row">
                      <div class=" w-70">
                        <Field
                          name="驗證碼"
                          type="text"
                          class="form-control"
                          placeholder="輸入驗證碼"
                          id="register8"
                          :class="{ 'is-invalid': errors['驗證碼'] }"
                          autocomplete="off"
                          maxlength="4"
                          tabindex="3"
                          rules="required"
                          v-model="form.Captcha"
                        ></Field>
                        <ErrorMessage
                          name="驗證碼"
                          class="invalid-feedback"
                        ></ErrorMessage>
                      </div>
                      <button
                        class="verifyBtn border w-30 position-relative form-control overflow-hidden"
                        type="button"
                        @click.prevent="getRandomNum"
                      >
                        <img
                          src=""
                          alt=""
                          class="w-100 position-absolute top-0 start-0 center"
                          id="validCodeRegister"
                        />
                        <img
                          src="@/assets/img/Icons-35.svg"
                          class="w-20 position-absolute end-0 center2"
                          style="z-index:2"
                        />
                      </button>
                    </div>
                    <div class="text-end fs-7 opacity">
                      驗證碼不須區分大小寫
                    </div>
                    <div class="form-check mt-4 mt-ld-5">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="mailSubscribe"
                        name="電子報"
                        v-model="form.IsSubscribe"
                      />
                      <label
                        class="form-check-label fs-md-6 fw-bold"
                        for="mailSubscribe"
                      >
                        我願意收到電子報
                      </label>
                    </div>
                    <div class="form-check  mt-1 mt-ld-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="agreementChecked"
                        name="必須同意"
                        v-model="Must"
                      />
                      <label
                        class="form-check-label fs-md-6 fw-bold"
                        for="agreementChecked"
                      >
                        我已詳閱並同意<a class="text-primary" href="#"
                          >約定條款</a
                        >以確保權益
                      </label>
                    </div>
                    <button
                      class="btn btn-primary w-100 mt-3 p-2"
                      type="submit"
                    >
                      立即加入
                    </button>
                  </Form>
                </div>
                <!-- ===分頁2會員登入(已整合舊會員及新會員)=== -->
                <div
                  class="tab-pane  px-4 pt-5 pb-3 "
                  id="oldMember"
                  role="tabpanel"
                  :class="{ active: $store.state.loginState === 'login' }"
                >
                  <Form
                    class="form_registration"
                    v-slot="{ errors }"
                    @submit="memberLogin"
                  >
                    <!-- //* --身分證或E-MAil登入-- -->
                    <label class=" " for="login1">*帳號</label>
                    <div class="  mb-4">
                      <Field
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors['身分證或Email'] }"
                        id="login1"
                        placeholder="請輸入身分證/Email/手機"
                        name="身分證或Email"
                        :rules="isOldAccount"
                        autocomplete="off"
                        v-model="login.Account"
                      ></Field>
                      <ErrorMessage
                        name="身分證或Email"
                        class="invalid-feedback"
                      ></ErrorMessage>
                    </div>
                    <!-- //* --輸入密碼登入-- -->
                    <label class=" " for="login2">*密碼</label>
                    <div class="position-relative mb-4 ">
                      <Field
                        type="password"
                        class="form-control"
                        :class="{ 'is-invalid': errors['密碼'] }"
                        id="login2"
                        placeholder="請輸入密碼"
                        name="密碼"
                        :rules="checkPWD"
                        autocomplete="off"
                        v-model="login.SecretCode"
                      ></Field>
                      <ErrorMessage
                        name="密碼"
                        class="invalid-feedback"
                      ></ErrorMessage>
                      <a
                        href="#"
                        class="position-absolute input-icon "
                        @click.prevent="
                          passWordShow = !passWordShow;
                          showPW($event)
                        "
                      >
                        <div></div>
                      </a>
                    </div>
                    <!-- //* --記住帳號-- -->
                    <div class="form-check  mb-4">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="rememberOldAccount"
                        name="記住我"
                        v-model="login.RememberMe"
                      />
                      <div class="d-flex justify-content-between">
                        <label
                          class="form-check-label"
                          for="rememberOldAccount"
                          id="usualFont"
                        >
                          記住帳號
                        </label>
                        <a
                          href="#"
                          @click.prevent="toForgetPassword"
                          class="text-primary fw-bold"
                        >
                          忘記密碼
                        </a>
                      </div>
                    </div>
                    <!-- //* --亂數驗證碼-- -->
                    <label class="valid" for="login3">*驗證碼</label>
                    <div class="d-flex flex-row">
                      <div class="w-70">
                        <Field
                          name="驗證碼"
                          type="text"
                          id="login3"
                          class="form-control "
                          :class="{ 'is-invalid': errors['驗證碼'] }"
                          placeholder="輸入驗證碼"
                          autocomplete="off"
                          maxlength="4"
                          tabindex="3"
                          rules="required"
                          v-model="login.Captcha"
                        />
                        <ErrorMessage
                          name="驗證碼"
                          class="invalid-feedback"
                        ></ErrorMessage>
                      </div>
                      <button
                        class="verifyBtn border w-30 position-relative form-control overflow-hidden"
                        type="submit"
                        @click.prevent="getRandomNum"
                      >
                        <img
                          src=""
                          alt=""
                          class="w-100 position-absolute top-0 start-0 center"
                          id="validCode"
                        />
                        <img
                          src="@/assets/img/Icons-35.svg"
                          class="w-20 position-absolute end-0 center2"
                          style="z-index:2"
                        />
                      </button>
                    </div>
                    <div class="text-end fs-7 opacity mb-4">
                      驗證碼不須區分大小寫
                    </div>
                    <button
                      type="submit"
                      class="btn btn-primary w-100 mt-3 p-2"
                    >
                      登入
                    </button>
                  </Form>
                </div>
                <!-- ===分頁3新會員登入=== -->
                <!-- <div
                  class="tab-pane f  px-4 pt-5 pb-3 "
                  id="member"
                  role="tabpanel"
                  :class="{ active: $store.state.loginState === 'new' }"
                >
                  <Form
                    class="form_registration"
                    v-slot="{ errors }"
                    @submit="login"
                  >
                    //* --手機登入
                    <label class=" " for="loginN1">*帳號</label>
                    <div class="  mb-4">
                      <Field
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors['手機'] }"
                        id="loginN1"
                        placeholder="請輸入手機號碼"
                        name="手機"
                        :rules="isNewAccount"
                        autocomplete="off"
                        v-model="newLogin.PhoneNum"
                      ></Field>
                      <ErrorMessage
                        name="手機"
                        class="invalid-feedback"
                      ></ErrorMessage>
                    </div>
                    //* --輸入密碼登入
                    <label class=" " for="loginN2">*密碼</label>
                    <div class="position-relative mb-4 ">
                      <Field
                        type="password"
                        class="form-control "
                        :class="{ 'is-invalid': errors['密碼'] }"
                        id="loginN2"
                        placeholder="請輸入密碼"
                        name="密碼"
                        :rules="checkPWD"
                        autocomplete="off"
                        v-model="newLogin.SecretCode"
                      ></Field>
                      <ErrorMessage
                        name="密碼"
                        class="invalid-feedback"
                      ></ErrorMessage>
                      <a
                        href="#"
                        class="position-absolute input-icon "
                        @click.prevent="
                          passWordShow = !passWordShow;
                          showPW($event)
                        "
                      >
                        <div></div>
                      </a>
                    </div>
                    //* --記住帳號
                    <div class="form-check  mb-4">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="rememberNewAccount"
                        name="記住我"
                        v-model="newLogin.RememberMe"
                      />
                      <div class="d-flex justify-content-between">
                        <label
                          class="form-check-label"
                          for="rememberNewAccount"
                          id="usualFont"
                        >
                          記住帳號
                        </label>
                        <a
                          href="#"
                          @click.prevent="toForgetPassword"
                          class="text-primary fw-bold"
                        >
                          忘記密碼
                        </a>
                      </div>
                    </div>
                    //* --亂數驗證碼
                    <label class=" valid" for="loginN3">*驗證碼</label>
                    <div class="d-flex flex-row">
                      <div class="w-70">
                        <Field
                          name="驗證碼"
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': errors['驗證碼'] }"
                          placeholder="輸入驗證碼"
                          autocomplete="off"
                          maxlength="4"
                          tabindex="3"
                          rules="required"
                          v-model="newLogin.Captcha"
                          id="loginN3"
                        ></Field>
                        <ErrorMessage
                          name="驗證碼"
                          class="invalid-feedback"
                        ></ErrorMessage>
                      </div>
                      <button
                        class="verifyBtn border w-30 position-relative form-control overflow-hidden"
                        type="button"
                        @click.prevent="getRandomNum"
                      >
                        <img
                          src=""
                          alt=""
                          class="w-100 position-absolute top-0 start-0 center"
                          id="validCode2"
                        />
                        <img
                          src="@/assets/img/Icons-35.svg"
                          class="w-20 position-absolute end-0 center2"
                          style="z-index:2"
                        />
                      </button>
                    </div>
                    <div class="text-end fs-7 opacity mb-4">
                      驗證碼不須區分大小寫
                    </div>
                    <button class="btn btn-primary w-100 mt-3 p-2">登入</button>
                  </Form>
                </div> -->
              </div>
            </div>
            <div
              class="col-12  col-lg-5 d-none d-md-block align-items-md-start px-3 px-md-0 pb-3 pb-md-0 "
              id="banner"
            >
              <img
                src="@/assets/img/Icons-38_pc.jpg"
                alt=""
                class="w-100 mb-4"
              />
            </div>
            <div
              class="col-12  col-lg-5 d-block d-md-none bg-white align-items-md-start ms-md-4 ms-lg-5 px-3 px-md-0 pb-3 pb-md-0 "
              id="banner"
            >
              <img
                src="@/assets/img/Icons-38_mb.jpg"
                alt=""
                class="w-100 mb-4"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- loading 動畫 -->
      <loading :active="isLoading" class="bottom-fixed position-absolute top-50 start-50 translate-middle" id="overlay" backgroundColor= 'transparent' blur="">
        <div class="loading1">
          <div class="demo1"></div>
          <div class="demo1"></div>
          <div class="demo1"></div>
          <div class="demo1"></div>
          <div class="demo1"></div>
        </div>
      </loading>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vue3-date-time-picker'
import 'vue3-date-time-picker/dist/main.css'
import ValidSvc from '@/service/validate-service.js'
import CartSvc from '@/service/cart-service.js'
import { ref } from 'vue'
import moment from 'moment'
import getGiftEvent from '@/assets/js/giftevent.js'
// import axios from 'axios'
// import Swal from 'sweetalert2/dist/sweetalert2.js'

export default {
  data () {
    return {
      date2: '',
      startDate: new Date(1990, 1, 1).toISOString(),
      passWordShow: false, // 密碼顯示
      checkPassWord: false, // 密碼再次確認顯示
      memberGender: 1,
      isShow: true,
      isOldShow: true,
      isConfirmShow: true,
      checkEmailMsg: '',
      confirmNotSame: '',
      confirmPassword: '',
      password: '',
      timer: 0,
      show: true,
      count: 0,
      terms: false,
      randomNum: '',
      //* 註冊api request
      form: {
        FullName: '',
        Email: '',
        PhoneNum: '',
        SecretCode: '',
        TransactionId: '',
        OtpCode: '',
        Captcha: '',
        ValidTransactionId: '',
        UserBirthday: '',
        RememberMe: false,
        IsSubscribe: true
      },
      Must: true /* 勾選判斷 */,
      // //* 新會員登入api request
      // newLogin: {
      //   PhoneNum: '',
      //   SecretCode: '',
      //   Captcha: '',
      //   ValidTransactionId: '',
      //   RememberMe: false
      // },
      //* 舊會員登入api request
      login: {
        Account: '',
        SecretCode: '',
        Captcha: '',
        ValidTransactionId: '',
        RememberMe: false
      },
      showRememberMe: true,
      msg: {
        phoneNumber: '',
        otpType: '1'
      },
      mobileMsg: {
        mobileMsgCode: []
      },
      register: {
        registerSuccess: []
      },
      isClickCart: false,
      clickValidCode: 0,
      transactionInfo: {},
      myModal: '',
      phoneNumOld: '',
      isLoading: false
    }
  },
  methods: {
    getDate () {
      const format = date => {
        const day = date.getDate().toISOString()
        const month = date.getMonth().toISOString()
        const year = date.getFullYear().toISOString()
        return `Selected date is ${year}/${month}}/${day}`
      }
      return format
    },
    transDate () {
      this.form.UserBirthday = moment(this.form.UserBirthday).format(
        'YYYY-MM-DD'
      )
    },
    showPW ($event) {
      if (this.passWordShow) {
        $event.currentTarget.previousSibling.previousSibling.setAttribute(
          'type',
          'text'
        )
      } else {
        $event.currentTarget.previousSibling.previousSibling.setAttribute(
          'type',
          'password'
        )
      }
    },
    showCkPW ($event) {
      if (this.checkPassWord) {
        $event.currentTarget.previousSibling.previousSibling.setAttribute(
          'type',
          'password'
        )
      } else {
        $event.currentTarget.previousSibling.previousSibling.setAttribute(
          'type',
          'text'
        )
      }
    },
    //* 取得隨機4位數驗證碼
    async getRandomNum () {
      // 圖形驗證碼的圖
      // 舊會員登入傳入src
      ValidSvc.GetValidateCode().then(SvcDataRegister => {
        this.form.ValidTransactionId = SvcDataRegister.transactionId
        document.getElementById('validCodeRegister').src =
          SvcDataRegister.validateImg
      })
      ValidSvc.GetValidateCode().then(SvcData => {
        this.login.ValidTransactionId = SvcData.transactionId
        document.getElementById('validCode').src = SvcData.validateImg
      })
      // ValidSvc.GetValidateCode().then(SvcData2 => {
      //   this.newLogin.ValidTransactionId = SvcData2.transactionId
      //   document.getElementById('validCode2').src = SvcData2.validateImg
      // })
      // ==另一種寫法===
      // const SvcData = await ValidSvc.GetValidateCode()
      // document.getElementById('validCode').src = SvcData.validateImg
    },
    //* 手機驗證碼
    getMobileMsgCode () {
      this.isLoading = true
      //* 點擊驗證碼，一天內不得超過三次限制
      const time = new Date()
      const getTimer = time.getTime()
      //* 一天的時間(86400)
      time.setTime(getTimer + 1000 * (86400 - 100))
      const utc = time.toUTCString()
      document.cookie = `vaildToken=${this.clickValidCode}; expires=${utc}`
      const vaildCodeToken = document.cookie.replace(
        /(?:(?:^|.*;\s*)vaildToken\s*=\s*([^;]*).*$)|^.*$/,
        '$1'
      )
      //* 點擊三次驗證碼送出
      if (vaildCodeToken === 3) {
        this.isLoading = false
        this.$swal.fire({
          title: '您今日已超過手機驗證碼傳送次數，請明天再試。',
          allowOutsideClick: false,
          confirmButtonColor: '#003894',
          confirmButtonText: '確認',
          width: 400,
          customClass: {
            title: 'text-class',
            confirmButton: 'confirm-btn-class'
          }
        })
        return
      }
      //* 確認手機是否有填寫
      this.msg.phoneNumber = this.form.PhoneNum
      var reg = /^(09)[0-9]{8}$/
      if (this.msg.phoneNumber === '') {
        this.isLoading = false
        this.$swal.fire({
          title: '請輸入手機號碼',
          allowOutsideClick: true,
          confirmButtonColor: '#003894',
          confirmButtonText: '確認',
          width: 400,
          customClass: {
            title: 'text-class',
            confirmButton: 'confirm-btn-class'
          }
        })
        //* 確認手機是否有正確的格式
      } else if (!reg.test(this.msg.phoneNumber)) {
        this.isLoading = false
        this.$swal.fire({
          title: '手機格式不正確',
          allowOutsideClick: true,
          confirmButtonColor: '#003894',
          confirmButtonText: '確認',
          width: 400,
          customClass: {
            title: 'text-class',
            confirmButton: 'confirm-btn-class'
          }
        })
      } else {
        const url = `${process.env.VUE_APP_API}/api/members/CallNewsletter`
        this.axios
          .get(url, {
            // get params用法
            params: this.msg
          })
          .then(res => {
            if (res.data.rtnCode !== 0) {
              this.isLoading = false
              this.$swal.fire({
                title: `${res.data.rtnMsg}`,
                allowOutsideClick: true,
                confirmButtonColor: '#003894',
                confirmButtonText: '確認',
                width: 400,
                customClass: {
                  title: 'text-class',
                  confirmButton: 'confirm-btn-class'
                }
              })
            } else {
              //* 有成功打入API才算
              this.isLoading = false
              this.clickValidCode++
              this.form.TransactionId = res.data.info.transactionId
              this.$swal.fire({
                title: 'Papawash驗證碼已發送！！',
                allowOutsideClick: true,
                confirmButtonColor: '#003894',
                confirmButtonText: '確認',
                width: 400,
                customClass: {
                  title: 'text-class',
                  confirmButton: 'confirm-btn-class'
                }
              })
              //* 驗證碼倒數計時
              this.count = 300
              this.show = false
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= 300) {
                  this.count--
                } else {
                  this.show = true
                  clearInterval(this.timer)
                  this.timer = null
                }
              }, 1000)
            }
          })
      }
    },
    //* 分頁1確認註冊後，會員直接登入
    registerSubmit () {
      if (this.Must === false) {
        this.form.Captcha = ''
        this.getRandomNum()
        this.$swal.fire({
          title: '請勾選權益說明書',
          allowOutsideClick: true,
          confirmButtonColor: '#003894',
          confirmButtonText: '確認',
          width: 400,
          customClass: {
            title: 'text-class',
            confirmButton: 'confirm-btn-class'
          }
        })
      } else {
        //* 註冊API
        //* 回傳memberToken, timeExpiredSeconds
        this.transDate() // ?轉換生日格式
        const url = `${process.env.VUE_APP_API}/api/members/register`
        this.axios.post(url, this.form).then(res => {
          if (res.data.rtnCode !== 0) {
            this.$swal
              .fire({
                title: `${res.data.rtnMsg}`,
                allowOutsideClick: true,
                confirmButtonColor: '#003894',
                confirmButtonText: '確認',
                width: 400,
                customClass: {
                  title: 'text-class',
                  confirmButton: 'confirm-btn-class'
                }
              })
              .then(result => {
                if (result.isConfirmed) {
                  this.count = 0
                  this.form.Captcha = ''
                  this.getRandomNum()
                }
              })
          } else {
            window.fbq('track', 'CompleteRegistration')
            this.$store.dispatch('loginSetCookie', res.data.info)
            this.$store.commit('changeLoginState', true)
            this.$swal
              .fire({
                title: '註冊成功',
                allowOutsideClick: true,
                confirmButtonColor: '#003894',
                confirmButtonText: '確認',
                width: 400,
                customClass: {
                  title: 'text-class',
                  confirmButton: 'confirm-btn-class'
                }
              })
              .then(result => {
                if (result.isConfirmed) {
                  if (sessionStorage.getItem('path')) {
                    this.checkFromLine()
                    return
                  }
                  this.$router.go(-1)
                }
              })
          }
        })
      }
    },
    //* 分頁2會員登入(已整合舊會員及新會員)
    memberLogin () {
      window.fbq('track', 'Lead')
      const id = /^[a-z](1|2)\d{8}$/i
      if (id.test(this.login.Account)) {
        this.login.Account = this.login.Account.toUpperCase()
      }
      const url = `${process.env.VUE_APP_API}/api/members/Login`
      this.axios.post(url, this.login).then(res => {
        this.login.Captcha = ''
        this.getRandomNum()
        if (res.data.rtnCode !== 0) {
          this.$swal.fire({
            title: `${res.data.rtnMsg}`,
            allowOutsideClick: false,
            confirmButtonColor: '#003894',
            confirmButtonText: '確認',
            width: 400,
            customClass: {
              title: 'text-class',
              confirmButton: 'confirm-btn-class'
            }
          })
          return
        }
        // * 取得購物車商品數量
        CartSvc.GetCart()
        this.$store.dispatch('loginSetCookie', res.data.info)
        this.$store.commit('changeLoginState', true)
        this.$swal
          .fire({
            title: '歡迎登入',
            allowOutsideClick: false,
            confirmButtonColor: '#003894',
            confirmButtonText: '確認',
            width: 400,
            customClass: {
              title: 'text-class',
              confirmButton: 'confirm-btn-class'
            }
          })
          .then(result => {
            if (result.isConfirmed) {
              if (sessionStorage.getItem('path')) {
                this.checkFromLine()
                return
              }
              if (this.$store.state.isClickCart === true) {
                this.$router.push('/checkoutboard/checkoutcart')
              } else {
                this.$router.go(-1)
              }
              // if (this.$store.state.BirthdayData.fromBirthday) {
              //   this.$router.push('/memberboard/membercoupon')
              //   return
              // }
              // // 若有記錄前一頁的路由，則導到前一頁面，否則導到會員專區
              // if (this.$store.state.clickCartPath) {
              //   this.$router.push(`${this.$store.state.clickCartPath}`)
              // } else if (this.$store.state.isClickCollection) {
              //   this.$router.push(`${this.$store.state.clickCollectionPath}`)
              // } else {
              //   if (this.$store.state.isOldLogin) {
              //     this.$router.push('/memberboard/membercoupon')
              //   } else {
              //     const appId = document.cookie.replace(
              //       /(?:(?:^|.*;\s*)appId\s*=\s*([^;]*).*$)|^.*$/,
              //       '$1'
              //     )
              //     if (appId === 'ipasspay') {
              //       this.$router.push('/')
              //       return
              //     }
              //     this.$router.push('/memberboard/memberinfoupdate')
              //   }
              // }
            }
          })
      })
    },
    //* 分頁3會員登入
    // login () {
    //   window.fbq('track', 'Lead')
    //   const url = `${process.env.VUE_APP_API}/api/members/NewLogIn`
    //   this.axios.post(url, this.newLogin).then(res => {
    //     this.newLogin.Captcha = ''
    //     this.getRandomNum()
    //     if (res.data.rtnCode !== 0) {
    //       this.$swal.fire({
    //         title: `${res.data.rtnMsg}`,
    //         allowOutsideClick: false,
    //         confirmButtonColor: '#003894',
    //         confirmButtonText: '確認',
    //         width: 400,
    //         customClass: {
    //           title: 'text-class',
    //           confirmButton: 'confirm-btn-class'
    //         }
    //       })
    //       return
    //     }
    //     // * 取得購物車商品數量
    //     CartSvc.GetCart()
    //     this.cookieToken = res.data.info
    //     this.$store.dispatch('loginSetCookie', res.data.info)
    //     this.$store.commit('changeLoginState', true)
    //     this.$swal
    //       .fire({
    //         title: '歡迎登入',
    //         allowOutsideClick: false,
    //         confirmButtonColor: '#003894',
    //         confirmButtonText: '確認',
    //         width: 400,
    //         customClass: {
    //           title: 'text-class',
    //           confirmButton: 'confirm-btn-class'
    //         }
    //       })
    //       .then(result => {
    //         if (result.isConfirmed) {
    //           if (sessionStorage.getItem('path')) {
    //             this.checkFromLine()
    //             return
    //           }
    //           if (this.$store.state.isClickCart === true) {
    //             this.$router.push('/checkoutboard/checkoutcart')
    //           } else {
    //             this.$router.go(-1)
    //           }
    //           // if (this.$store.state.BirthdayData.fromBirthday) {
    //           //   this.$router.push('/memberboard/membercoupon')
    //           //   return
    //           // }
    //           // // 若有記錄前一頁的路由，則導到前一頁面，否則導到會員專區
    //           // if (this.$store.state.clickCartPath) {
    //           //   this.$router.push(`${this.$store.state.clickCartPath}`)
    //           // } else if (this.$store.state.isClickCollection) {
    //           //   this.$router.push(`${this.$store.state.clickCollectionPath}`)
    //           // } else {
    //           //   const appId = document.cookie.replace(
    //           //     /(?:(?:^|.*;\s*)appId\s*=\s*([^;]*).*$)|^.*$/,
    //           //     '$1'
    //           //   )
    //         }
    //       })
    //   })
    // },
    toForgetPassword () {
      this.$router.push('/forgetpwd')
    },
    // * 判斷是否從line進入此頁(SessionStorage值)
    checkFromLine () {
      getGiftEvent().then((data) => {
        if (!data.isSuccess) {
          this.$swal.fire({
            title: `${data.message}(${data.rtnCode})`,
            allowOutsideClick: false,
            confirmButtonColor: '#003894',
            confirmButtonText: '確認',
            width: 400,
            customClass: {
              title: 'text-class',
              confirmButton: 'confirm-btn-class'
            }
          })
        } else {
          this.$swal.fire({
            title: `${data.message}`,
            allowOutsideClick: false,
            confirmButtonColor: '#003894',
            confirmButtonText: '確認',
            width: 400,
            customClass: {
              title: 'text-class',
              confirmButton: 'confirm-btn-class'
            }
          })
        }
        this.$router.push(sessionStorage.getItem('path'))
        sessionStorage.removeItem('path')
        return true
      })
      return false
    },

    // ===呼叫驗證方法===
    checkEmail (email) {
      return ValidSvc.CheckEmail(email)
    },
    checkPWD (pwd) {
      return ValidSvc.CheckPwd(pwd)
    },
    checkPWDagain (password) {
      return ValidSvc.ConfirmPwd(password, this.form.SecretCode)
    },
    isPhone (phone) {
      return ValidSvc.CheckPhoneNum(phone)
    },
    // ---舊會員---
    isOldAccount (account) {
      return ValidSvc.CheckOldAccount(account)
    },
    // ---新會員---
    isNewAccount (account) {
      return ValidSvc.CheckPhoneNum(account)
    },
    // ---清空頁籤狀態---
    changeVuexLoginState (state) {
      this.$store.commit('refreshloginState', state)
    },
    // ? 驗證姓名長度不超過 5
    isNameToLong (value) {
      if (value) {
        const nameString = /^.{0,5}$/
        return nameString.test(value) ? true : '長度請勿超過 5 個字'
      } else {
        return true
      }
    }
  },
  components: {
    Datepicker
  },
  created () {
    this.getDate()
  },
  mounted () {
    this.getRandomNum()
  },
  watch: {
    $route (to, from) {
      this.$store.commit('refreshClickCart', false)
    }
  },
  setup () {
    const date = ref(new Date())
    // In case of a range picker, you'll receive [Date, Date]
    const format = date => {
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()
      return ` ${year}-${month}-${day}`
    }

    return {
      date,
      format
    }
  }
}
</script>

<style lang="scss" scoped>
.registerlogin {
  background-color: #f0f0f2;
}

.nav-link {
  padding: 1rem 1rem;
  background-color: #fafafa;
  color: #000000;
  @media screen and (max-width: 576px) {
    padding: 0.6rem 1rem;
  }
}
.nav-tabs {
  border-bottom: 0px;
}
.nav-tabs .nav-link {
  border-radius: 0%;
  border: 1px solid #808080;
}
.nav-tabs .nav-link.active {
  border-top: 1px solid #808080;
  border-right: 1px solid #808080;
  border-left: 1px solid #808080;
  border-bottom: 0px;
}
.nav-link.active {
  background-color: #fff;
  color: #003894;
}
.tab-content .active {
  border: 1px solid #808080;
  border-top: 0px;
}

.input-icon {
  width: 26px;
  top: 12px;
  right: 26px;
  @media screen and (max-width: 576px) {
    right: 16px;
  }
}
.form-control {
  height: calc(2.5rem + 2px) !important;
  // font-size: 1.3rem !important;
}
.dp__input {
  padding: 0.1rem 0.25rem !important;
}

.dp-custom-cell {
  border-radius: 50% !important;
}
.v-leave {
  opacity: 0.5;
}
.v-leave-active {
  transition: opacity ease-out 0.1s;
}
.v-leave-to {
  opacity: 0;
}
.v-enter {
  opacity: 0.2;
}
.v-enter-active {
  transition: opacity ease-in-out 1s;
}
.v-enter-to {
  opacity: 0.7;
}

label {
  @media screen and (max-width: 768px) {
    font-size: 0.75rem !important;
  }
  @media screen and (max-width: 576px) {
    font-size: 1rem !important;
  }
}
.form-floating > label {
  padding: 0.1rem 0.25rem;
}
.valid {
  font-size: 1rem;
  @media screen and (max-width: 768px) {
    font-size: 0.75rem !important;
  }
  @media screen and (max-width: 576px) {
    font-size: 1rem !important;
  }
}

#register,
#oldMember,
#member {
  @media screen and (max-width: 767px) {
    border-bottom: none;
  }
}
#banner {
  @media screen and (max-width: 767px) {
    border: 1px solid #808080;
    border-top: none;
  }
}
.is-invalid {
  border-color: #db0031;
  background-image: none;
}
.invalid-feedback {
  color: #db0031;
  // position: absolute;
  // margin-top: 0px;
}

#usualFont {
  font-size: 1rem !important;
}
.center {
  transform: translate(2%, -15%);
  @media screen and (max-width: 1200px) {
    transform: translate(2%, 8%);
  }
  @media screen and (max-width: 1000px) {
    transform: translate(2%, -22%);
  }
  @media screen and (max-width: 768px) {
    transform: translate(-1%,-23%);
  }
  @media screen and (max-width: 576px) {
    transform: translate(-7%,-15%);
  }
  @media screen and (max-width: 432px) {
    transform: translate(-7%,-6%);
  }
  @media screen and (max-width: 376px) {
    transform: translate(-3%, 10%);
  }
}
.center2 {
  transform: translate(-35%, -55%);
  @media screen and (max-width: 767px) {
    transform: translate(-33%, -50%);
  }
}
.opacity {
  opacity: 0.65;
}

.alive {
  display: block;
}

.hide {
  display: none;
}
input#register6[type='text'] ~ a > div {
  background-image: url('../assets/img/Icons-33.svg');
  background-repeat: repeat;
  background-position: center top;
  background-size: cover;
  width: 25px;
  height: 20px;
}
input#register6[type='password'] ~ a > div {
  background-image: url('../assets/img/Icons-34.svg');
  background-repeat: repeat;
  background-position: center top;
  background-size: cover;
  width: auto;
  height: 13px;
}
input#register7[type='text'] ~ a > div {
  background-image: url('../assets/img/Icons-33.svg');
  background-repeat: repeat;
  background-position: center top;
  background-size: cover;
  width: 25px;
  height: 20px;
}
input#register7[type='password'] ~ a > div {
  background-image: url('../assets/img/Icons-34.svg');
  background-repeat: repeat;
  background-position: center top;
  background-size: cover;
  width: auto;
  height: 13px;
}
input#login2[type='text'] ~ a > div {
  background-image: url('../assets/img/Icons-33.svg');
  background-repeat: repeat;
  background-position: center top;
  background-size: cover;
  width: 25px;
  height: 20px;
}
input#login2[type='password'] ~ a > div {
  background-image: url('../assets/img/Icons-34.svg');
  background-repeat: repeat;
  background-position: center top;
  background-size: cover;
  width: auto;
  height: 13px;
}
input#loginN2[type='text'] ~ a > div {
  background-image: url('../assets/img/Icons-33.svg');
  background-repeat: repeat;
  background-position: center top;
  background-size: cover;
  width: 25px;
  height: 20px;
}
input#loginN2[type='password'] ~ a > div {
  background-image: url('../assets/img/Icons-34.svg');
  background-repeat: repeat;
  background-position: center top;
  background-size: cover;
  width: auto;
  height: 13px;
}
</style>
